.breadcrumb {
  padding: unset !important;
  margin-bottom: unset !important;
  background-color: unset !important;
}


.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}

